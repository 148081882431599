// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn: 'https://7e473217687c4554990886b04f63e01a@o1223220.ingest.sentry.io/6376843',
  environment: process.env.SENTRY_ENV,
  release: process.env.BITBUCKET_COMMIT,
  enabled: process.env.BITBUCKET_COMMIT !== undefined,
  integrations: [new Sentry.BrowserTracing()],
  sampleRate: 1,
  tracesSampleRate: 0.03,
});
